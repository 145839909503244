import { jwtDecode } from 'jwt-decode'
import {
  AppCustomEvent,
  DynamoFile,
  EPlusFolder,
  FileCommentTopic,
  FileStructure,
  OfficeRuntimeAccessToken,
  OfficeTokenCookie,
  TestDataOcr,
} from '../types'
import { isOfficeRuntimeToken } from './guards'
import { fetchPDFBatchFromLocal, fetchPDFFromLocal } from '../workbook'
import { getFileBatchV2, getFileV2 } from '../api'
import Cookies from 'js-cookie'
import numeral from 'numeral'
export const fileContainsComment = (
  file: DynamoFile,
  comments: FileCommentTopic[]
) => comments.some((topic) => topic.fileId === file.fileId)

export const publishOnReferenceSelectedEvent = (bindingId: string) => {
  const event = new CustomEvent(AppCustomEvent.ON_REFERENCE_SELECTED, {
    detail: { bindingId },
  })
  window.dispatchEvent(event)
}

export const getOfficeErrorTitle = (message: string) => {
  if (message.startsWith('Excel is in cell-editing mode'))
    return 'Excel is in cell-editing mode'
  return message
}

export const getOfficeRuntimeAccessToken =
  async (): Promise<OfficeRuntimeAccessToken> => {
    let token: string | undefined
    token = Cookies.get(OfficeTokenCookie.KEY)
    if (!token) {
      token = await Office.auth.getAccessToken({
        allowConsentPrompt: true,
        allowSignInPrompt: true,
      })
      if (!token) throw new Error('Cannot get access token')
      Cookies.set(OfficeTokenCookie.KEY, token, {
        expires: 1 / 1440,
        sameSite: 'Strict',
        secure: true,
      })
    }
    const decoded = jwtDecode(token)
    if (!isOfficeRuntimeToken(decoded))
      throw new Error('Internal Error: access denied')
    return {
      name: decoded.name,
      preferred_username: decoded.preferred_username,
      oid: decoded.oid,
    }
  }

export const publishBindingOnDataChangeEvent = (bindingId: string) => {
  const event = new CustomEvent(AppCustomEvent.BINDING_ON_DATA_CHANGE, {
    detail: { bindingId },
  })
  window.dispatchEvent(event)
}

export const getFileAndOcr = async (
  fileId: string,
  isLocalMode: boolean
): Promise<[string | Blob, TestDataOcr, string[]]> => {
  if (isLocalMode) {
    const f = await fetchPDFFromLocal(fileId)
    if (!f?.pdfBlob || !f.ocr)
      throw new Error(`Cannot fetch file ${fileId} from local`)
    return [f.pdfBlob, f.ocr, []]
  } else {
    const file = await getFileV2(fileId)
    return [file.pdf, file.ocr, file.ocrDateCache]
  }
}

export const getFileAndOcrBatch = async (
  fileIds: string[],
  isLocalMode: boolean
): Promise<[string, string | Blob, TestDataOcr, string[]][]> => {
  if (isLocalMode) {
    const files = await fetchPDFBatchFromLocal(fileIds)

    for (const f of files) {
      if (!f?.pdfBlob || !f.ocr)
        throw new Error(`Cannot fetch file ${f.fileId} from local`)
    }
    return files.map((f) => [f.fileId, f.pdfBlob, f.ocr, []])
  } else {
    const files = await getFileBatchV2(fileIds)
    return files.map((file) => [file.id, file.pdf, file.ocr, file.ocrDateCache])
  }
}

/**
 * Extracts the file name without the extension from a given file path.
 * @param filePath - The full path of the file.
 * @returns The file name without the extension.
 */
export const getFileName = (filePath: string): string => {
  const parts = filePath.split('/')
  const fullFileName = parts[parts.length - 1]
  const fileNameWithoutExtension = fullFileName
    .split('.')
    .slice(0, -1)
    .join('.')
  return fileNameWithoutExtension
}

export const fileSizeLessThan5MB = (size: number) =>
  size < numeral('5MB').value()!

export const getFileStructure = (
  folders: EPlusFolder[],
  files: { id: string; parentFolderId: string | undefined }[]
) => {
  const root: FileStructure = {}
  const folderMap = new Map<string, EPlusFolder>()
  const fileMap = new Map<string, typeof files>()
  files.forEach((file) => {
    if (!file.parentFolderId) {
      root[file.id] = null
    } else {
      if (fileMap.has(file.parentFolderId)) {
        fileMap.get(file.parentFolderId)!.push(file)
      } else {
        fileMap.set(file.parentFolderId, [file])
      }
    }
  })
  for (const folder of folders) {
    folderMap.set(folder.fileId, folder)
  }

  const parseFolder = (folder: EPlusFolder) => {
    let f = folder
    const parents: string[] = [folder.fileId]
    while (f.parentFolderId) {
      parents.push(f.parentFolderId)
      const tmp = folderMap.get(f.parentFolderId)
      if (!tmp) break
      f = tmp
    }
    parents.reverse()
    let r = root
    for (const p of parents) {
      if (!(p in r)) {
        r[p] = {}
      }
      r = r[p]!
    }
    const arr = fileMap.get(folder.fileId) ?? []
    for (const f of arr) {
      r[f.id] = null
    }
  }

  for (const folder of folders) {
    parseFolder(folder)
  }
  return root
}
