import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined'

type Props = {
  setSelectMultipleFiles: React.Dispatch<React.SetStateAction<boolean>>
  numberOfSelections: number
  resetSelectedFileRef: () => void
  showDeleteSelectionsPopup: () => void
  showMoveSelectionPopup: () => void
}

const MultiSelectionBar = ({
  setSelectMultipleFiles,
  numberOfSelections,
  resetSelectedFileRef,
  showDeleteSelectionsPopup,
  showMoveSelectionPopup,
}: Props) => {
  return (
    <div className="flex w-full rounded-full py-2.5 text-sm font-medium leading-5 bg-white items-center justify-start p-4 gap-2 ">
      <div className="relative group/move flex justify-center mr-2 hover:bg-gray-500  rounded-full">
        <button
          className="w-full h-full  p-1 transition ease-in-out delay-100 hover:scale-110"
          onClick={() => {
            resetSelectedFileRef()
            setSelectMultipleFiles(false)
          }}
        >
          <CloseOutlinedIcon sx={{ fontSize: 20, zIndex: 0 }} />
        </button>
        <div
          className={`absolute ${'top-14 before:-top-1'} scale-0 z-10  rounded bg-neutral-700 p-2 text-xs text-white group-hover/move:scale-100  group-hover/move:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
        >
          Clear selection
        </div>
      </div>

      <div className="text-sm rounded-full flex items-center justify-center font-semibold mr-2">
        {numberOfSelections} selected
      </div>

      <div className="relative group/move flex justify-center mr-2 hover:bg-gray-500  rounded-full">
        <button
          className="w-full h-full  p-1 transition ease-in-out delay-100 hover:scale-110"
          onClick={() => showDeleteSelectionsPopup()}
        >
          <DeleteOutlineOutlinedIcon sx={{ fontSize: 20, zIndex: 0 }} />
        </button>
        <div
          className={`absolute ${'top-14 before:-top-1'} scale-0 z-10  rounded bg-neutral-700 p-2 text-xs text-white group-hover/move:scale-100  group-hover/move:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
        >
          Delete selection
        </div>
      </div>

      <div className="relative group/move flex justify-center mr-2 hover:bg-gray-500  rounded-full">
        <button
          className="w-full h-full  p-1 transition ease-in-out delay-100 hover:scale-110"
          onClick={() => showMoveSelectionPopup()}
        >
          <DriveFileMoveOutlinedIcon sx={{ fontSize: 20, zIndex: 0 }} />
        </button>
        <div
          className={`absolute ${'top-14 before:-top-1'} scale-0 z-10  rounded bg-neutral-700 p-2 text-xs text-white group-hover/move:scale-100  group-hover/move:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
        >
          Move selection
        </div>
      </div>
    </div>
  )
}

export default MultiSelectionBar
