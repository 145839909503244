import { useEffect, useRef } from 'react'
import { AppCustomEvent } from '../types'
import { isCustomEvent } from '../utils/guards'
import { useAppDispatch, useAppSelector } from '../dispatch'
import { isEmptyRange } from '../workbook'
import { deleteReferencesWithBindingId } from '../slice/referenceSlice'
import { useQueue } from 'react-use'

const useBindingOnDataChangeEventReceiver = () => {
  const references = useAppSelector((state) => state.references.references)
  const dispatch = useAppDispatch()
  const { add, first, remove, size } = useQueue<string>()
  const lock = useRef(false)

  useEffect(() => {
    const handler = async (event: Event) => {
      if (!isCustomEvent(event)) return
      const filtered = references.find(
        (ref) => ref.bindingId === event.detail?.bindingId
      )
      if (!filtered) return
      if (filtered.bindingGroupId) {
        const bindingGroup = references.filter(
          (ref) => ref.bindingGroupId === filtered.bindingGroupId
        )
        bindingGroup.forEach((ref) => add(ref.bindingId))
      } else {
        add(filtered.bindingId)
      }
    }

    window.addEventListener(AppCustomEvent.BINDING_ON_DATA_CHANGE, handler)

    return () =>
      window.removeEventListener(AppCustomEvent.BINDING_ON_DATA_CHANGE, handler)
  }, [references, dispatch, add])

  useEffect(() => {
    const func = async () => {
      if (size > 0) {
        const bindingId = first
        const isEmpty = await isEmptyRange(bindingId).catch((err) =>
          console.error(err)
        )
        if (isEmpty && !lock.current) {
          lock.current = true
          await dispatch(deleteReferencesWithBindingId(bindingId))
            .catch((err) => console.error(err))
            .finally(() => {
              remove()
              lock.current = false
            })
        }
      }
    }

    func().catch((err) => console.error(`BindingOnDataChange Error: ${err}`))
  }, [dispatch, size, first, remove, lock])
}

export default useBindingOnDataChangeEventReceiver
