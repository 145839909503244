import { Dispatch, useEffect, useRef, useState } from 'react'
import useClickOutside from '../../hooks/useClickOutside'
import {
  DynamoFile,
  FileStatus,
  RotationType,
  SearchAllType,
  TestDataOcr,
  TextBlock,
} from '../../types'
import { getFileV2 } from '../../api'
import { ClockLoader } from 'react-spinners'
import { NavigateFunction } from 'react-router-dom'
import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { ReaderAPI } from '../PdfViewer/types'
import { useInfiniteQuery } from 'react-query'
import { useVirtualizer } from '@tanstack/react-virtual'
import { breakDownOcr } from '../../utils/ocr'
import { extractDateFromText } from '../../utils/date-util'
import dayjs from 'dayjs'
import { Date_Formats } from '../../utils/fuzzy'
import currency from 'currency.js'
import { getRangeText } from '../../workbook'

type Props = {
  files: DynamoFile[]
  degree: RotationType
  fileId: string
  filePage: number
  navigate: NavigateFunction
  setCurrentPage: Dispatch<React.SetStateAction<number>>
  setSearchAllItem: Dispatch<React.SetStateAction<string[]>>
  setFileId: Dispatch<React.SetStateAction<string>>
  setSearchAllInput: () => void
  readerAPI: ReaderAPI | null
}

const getFilesOCR = async (files: DynamoFile[]) => {
  const hashMap = new Map<[string, string], TestDataOcr>()

  const promises = files.map((file) => getFileV2(file.fileId))

  const result = await Promise.all(promises)

  for (const file of result) {
    hashMap.set([file.id, file.fileName], file.ocr)
  }

  return hashMap
}

// const worker = new Worker(
//   new URL('../../workers/search_worker.ts', import.meta.url)
// )

const searchFiles = async (
  files: DynamoFile[],
  limit: number,
  offset: number = 0,
  query: string,
  degree: number
) => {
  const start = offset * limit
  const end = start + limit
  const sliced = files.slice(start, end)
  if (!sliced || !sliced.length) {
    return { rows: [], nextOffset: offset + 1 }
  }
  const map = await getFilesOCR(sliced)
  let results: SearchAllType[] = []
  if (map) {
    for (const [k, v] of map) {
      const [fileId, fileName] = k
      const [pageMap, , , lineMap] = breakDownOcr(v, degree)
      const blocks: TextBlock[] = []
      for (const line of lineMap.values()) {
        if (
          line.description
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase())
        )
          blocks.push(line)
        else {
          const queryDate = extractDateFromText(query)
          const lineDate = extractDateFromText(line.description)
          if (queryDate.length && lineDate.length) {
            const qd = dayjs(queryDate[0], Date_Formats)
            const ld = dayjs(lineDate[0], Date_Formats)
            if (
              qd.isValid() &&
              ld.isValid() &&
              qd.format('DD/MM/YYYY') === ld.format('DD/MM/YYYY')
            ) {
              blocks.push(line)
            }
          } else {
            const qNum = currency(query)
            const lNum = currency(line.description)
            if (qNum.value && lNum.value && qNum.value === lNum.value) {
              blocks.push(line)
            }
          }
        }
      }
      for (const [pageNum, pageObj] of pageMap) {
        const filtered = blocks.filter((block) => {
          if (pageObj.relationships) {
            return pageObj.relationships[0].ids.includes(block.id)
          }
          return false
        })
        const arr: SearchAllType[] = filtered.map((block) => ({
          blockId: block.id,
          fileId,
          fileName,
          filePage: Number(pageNum),
        }))
        results = [...results, ...arr]
      }
    }
  }
  return { rows: results, nextOffset: offset + 1 }
}

const getSucceededFiles = (fileId: string, files: DynamoFile[]) => {
  const filtered = files.filter(
    (file) => file.type === 'FILE' && file.status === FileStatus.SUCCEEDED
  )

  return [
    ...filtered.filter((file) => file.fileId === fileId),
    ...filtered.filter((file) => file.fileId !== fileId),
  ]
}

const SearchAllComboBox = ({
  files,
  degree,
  navigate,
  filePage,
  fileId,
  setCurrentPage,
  setSearchAllItem,
  setSearchAllInput,
  readerAPI,
}: Props) => {
  const succeededFiles = getSucceededFiles(fileId, files)
  const [query, setQuery] = useState('')
  const containerRef = useRef<HTMLDivElement>(null)
  // const [, setIsError] = useState(false)

  const {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isError,
  } = useInfiniteQuery({
    queryKey: ['result', succeededFiles, query, degree.curr],
    queryFn: (ctx) => {
      const [, succeededFiles, query, degree] = ctx.queryKey
      return searchFiles(
        succeededFiles as DynamoFile[],
        10,
        ctx.pageParam,
        query as string,
        degree as number
      )
    },
    getNextPageParam: (lastGroup) => {
      return lastGroup.nextOffset * 10 > succeededFiles.length
        ? undefined
        : lastGroup.nextOffset
    },
    enabled: query !== '',
  })
  const allRows = data ? data.pages.flatMap((d) => d.rows) : []

  const parentRef = React.useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
    overscan: 5,
  })

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }
    if (
      lastItem.index >= allRows.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasNextPage,
    fetchNextPage,
    allRows.length,
    isFetchingNextPage,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    rowVirtualizer.getVirtualItems(),
  ])

  useEffect(() => {
    const allRows = data ? data.pages.flatMap((d) => d.rows) : []
    const ids = allRows
      .filter((re) => re.fileId === fileId && re.filePage === filePage)
      .map((re) => re.blockId)
    setSearchAllItem(ids)
  }, [fileId, filePage, setSearchAllItem, data])

  useEffect(() => {
    const handler = async (e: Event) => {
      if ('detail' in e) {
        try {
          const customEvent = e as unknown as CustomEvent
          const detail: { address: string; worksheetId: string } = JSON.parse(
            customEvent.detail
          )
          const values = await getRangeText(detail.worksheetId, detail.address)
          const value = values.at(0)?.at(0)
          if (value) {
            setQuery(value)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    window.addEventListener('OnExcelWorkbooksSelectionChange', handler)

    return () => {
      window.removeEventListener('OnExcelWorkbooksSelectionChange', handler)
    }
  }, [])

  const reset = () => {
    setSearchAllItem([])
    // enableRibbonAfterSearchAll()
    setSearchAllInput()
  }

  useClickOutside<HTMLDivElement>({
    ref: containerRef,
    action: () => {
      if (!query || !query.trim()) {
        reset()
      }
    },
  })
  // const setSearchAllItemHelper = useCallback(
  //   (fileId: string, filePage: number, results: SearchAllType[]) => {
  //     const ids = results
  //       .filter((re) => re.fileId === fileId && re.filePage === filePage)
  //       .map((re) => re.blockId)
  //     setSearchAllItem(ids)
  //   },
  //   [setSearchAllItem]
  // )

  const onItemClick = (item: [string, string, number]) => () => {
    const [itemFileId, , itemFilePage] = item
    if (itemFileId === fileId && itemFilePage === filePage) return
    if (itemFileId !== fileId) {
      // setSearchAllItemHelper(itemFileId, itemFilePage, possibleResults)
      navigate(`/file/${itemFileId}`, {
        replace: true,
        state: { page: itemFilePage },
      })
      return
    }
    if (filePage !== itemFilePage) {
      // setSearchAllItemHelper(fileId, itemFilePage, possibleResults)
      setCurrentPage(itemFilePage)
      readerAPI?.jumpToPage(itemFilePage - 1)
      return
    }
  }

  // const onSearchInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.code === 'Enter' && !isFetching && !isFetchingNextPage)
  //     setUseInfiniteQueryEnabled(true)
  // }

  const clearSearchField = () => {
    setQuery('')
  }

  return (
    <div
      ref={containerRef}
      // onMouseEnter={() => setShowOptions(true)}
      // onMouseLeave={() => setShowOptions(false)}
    >
      <div className="relative">
        <div className="relative w-full cursor-default overflow-hidden rounded-sm text-left shadow-md  focus:ring-2 focus:ring-teal-300/75 focus:ring-offset-2 focus:ring-offset-teal-300 sm:text-sm">
          <input
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 animate-pulse focus:animate-none"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Search files"
            autoComplete="off"
            autoCorrect="off"
          />

          {!isFetching && !isFetchingNextPage && (
            <div className="absolute flex inset-y-0 right-0 items-center pr-2">
              <button className="mr-1" onClick={clearSearchField}>
                <CloseOutlinedIcon sx={{ fontSize: 16 }} />
              </button>
              <button>
                <SearchOutlinedIcon sx={{ fontSize: 16 }} />
              </button>
            </div>
            // <button
            //   className="absolute inset-y-0 right-0 items-center pr-2"
            //   onClick={onSearchButtonClick}
            // >
            //   <MdOutlineSearch />
            // </button>
          )}

          {(isFetching || isFetchingNextPage) && (
            <button
              className="absolute inset-y-0 right-0 items-center pr-2"
              onClick={reset}
            >
              <ClockLoader color="#36d7b7" size={15} />
            </button>
          )}
        </div>

        {allRows.length > 0 && (
          <div
            ref={parentRef}
            className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
          >
            {/* {possibleResultMap.size === 0 && (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700 ">
                Nothing found.
              </div>
            )}
            {possibleResultMap.size > 0 &&
              Array.from(possibleResultMap.values()).map((item) => (
                <div
                  onClick={onItemClick(item)}
                  key={item.join('-')}
                  className={`relative cursor-default select-none py-2 pl-2 pr-4 
                                      hover:bg-teal-600 hover:text-white text-gray-900
                                    `}
                >
                  {`${item[1]}, page: ${item[2]}`}
                </div>
              ))} */}
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const isLoaderRow = virtualRow.index > allRows.length - 1
                const item = allRows[virtualRow.index]
                return (
                  <div
                    key={virtualRow.index}
                    className={`relative cursor-default select-none py-2 pl-2 pr-4 
                hover:bg-teal-600 hover:text-white text-gray-900
              `}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    {isLoaderRow ? (
                      hasNextPage ? (
                        <button className="w-full h-full">
                          {`Loading...`}
                        </button>
                      ) : (
                        <button className="w-full h-full">
                          {'Nothing more to load'}
                        </button>
                      )
                    ) : (
                      <button
                        onClick={onItemClick([
                          item.fileId,
                          item.fileName,
                          item.filePage,
                        ])}
                        className="w-full h-full"
                      >
                        {`${item.fileName}, page: ${item.filePage}`}
                      </button>
                    )}
                  </div>
                )
              })}
            </div>
            <div>
              {isFetching && !isFetchingNextPage
                ? 'Background Updating...'
                : null}
            </div>
          </div>
        )}
        {isError && <div>{'Search error'}</div>}
      </div>
    </div>
  )
}

export default SearchAllComboBox
