import parser from 'any-date-parser'
import dayjs from 'dayjs'

// Excel Date Number to Date
export function excelDateToDate(excelDate: number, format = 'DD/MM/YYYY') {
  // Convert Excel serial number to a date
  const date = dayjs('1899-12-30').add(excelDate, 'day')
  const output = date.format(format)
  console.log(output)
  return output
}

// Date to Excel Date Number
export function dateToExcelDate(date: string, format = 'DD/MM/YYYY') {
  // Convert date to Excel serial number
  const excelDate = dayjs(date, format).diff(dayjs('1899-12-30'), 'day')
  console.log(excelDate)
  return excelDate
}

/**
 * MM/DD/YYYY or MM-DD-YYYY (e.g., 12/31/2023 for the US)
 * DD/MM/YYYY or DD-MM-YYYY (e.g., 31/12/2023 for the UK/AUS)
 * YYYY-MM-DD (ISO format, e.g., 2023-12-31)
 * Month Day, Year (e.g., December 31, 2023)
 * Day Month Year (e.g., 31 December 2023)
 * DD/Mon/YYYY (e.g., 01/Jan/2023)
 * Mon/DD/YYYY (e.g., Jan/01/2023)
 * @param text
 * @returns dates
 */
export const extractDateFromText = (text: string): string[] => {
  const dateRegex =
    /\b(?:(\d{2})[/-](\d{2})[/-](\d{4})|(\d{4})[/-](\d{2})[/-](\d{2})|([A-Za-z]+) (\d{1,2}),? (\d{4})|(\d{1,2}) ([A-Za-z]+) (\d{4})|(\d{2})[/]([A-Za-z]{3,9})[/](\d{4})|([A-Za-z]{3,9})[/](\d{2})[/](\d{4}))\b/g

  return text.match(dateRegex) ?? []
}

/**
 * Extract date from text
 * if date is in (year month day) format
 * e.g. 2016-09-24, 2016-9-24, 20160924
 * @param text
 * @returns
 */
export const extractYearMonthDayFromText = (text: string) => {
  const re = /\b(\d{4})[-]?(0?[1-9]|1[0-2])[-]?(0?[1-9]|[12][0-9]|3[01])\b/g
  return text.match(re) ?? []
}

export const extractDayMonthNameYearFromText = (text: string) => {
  const re =
    /\b(0?[1-9]|[12][0-9]|3[01])[-\s]?(January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[-\s]?(20\d{2}|\d{2})\b/g
  return text.match(re) ?? []
}

export const extractMonthNameDayYearFromText = (text: string) => {
  const re =
    /\b(January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[\s](0?[1-9]|[12][0-9]|3[01])[, ]\s?(20\d{2})\b/g
  return text.match(re) ?? []
}

export const extractMonthDayYear = (text: string) => {
  const re =
    /\b(0?[1-9]|1[0-2])[-/](0?[1-9]|[12][0-9]|3[01])[-/](\d{4}|\d{2})\b/g
  return text.match(re) ?? []
}

export const extractDayMonthYear = (text: string) => {
  const re =
    /\b(0?[1-9]|[12][0-9]|3[01])[-/.](0?[1-9]|1[0-2])[-/.](\d{4}|\d{2})\b/g
  return text.match(re) ?? []
}

export const extractMonthNameDay = (text: string) => {
  const re =
    /\b(January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(0?[1-9]|[12][0-9]|3[01])\b/g
  return text.match(re) ?? []
}

export const extractDayMonthName = (text: string) => {
  const re =
    /\b(0?[1-9]|[12][0-9]|3[01])\s(January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\b/g
  return text.match(re) ?? []
}

export const extractMonthDay = (text: string) => {
  const re = /\b(0?[1-9]|1[0-2])[-/](0?[1-9]|[12][0-9]|3[01])\b/g
  return text.match(re) ?? []
}

export const extractDayMonth = (text: string) => {
  const re = /\b(0?[1-9]|[12][0-9]|3[01])[-/.](0?[1-9]|1[0-2])\b/g
  return text.match(re) ?? []
}

export const isValidDate = (
  date: string | undefined
): {
  valid: boolean
  date: any
} => {
  if (!date) {
    return {
      valid: false,
      date: '',
    }
  }
  const parsed = parser.attempt(date, 'en-AU')
  if ('invalid' in parsed)
    return {
      valid: false,
      date: '',
    }
  return { valid: true, date: parsed }
}

export const extractAndParseDate = (text: string): string | null => {
  let result = extractYearMonthDayFromText(text)
  let valid = isValidDate(result.at(0))
  if (valid.valid) {
    return dayjs(
      `${valid.date.year}-${valid.date.month}-${valid.date.day}`,
      'YYYY-M-D'
    ).format('YYYY-MM-DD')
  }

  result = extractDayMonthNameYearFromText(text)
  valid = isValidDate(result.at(0))
  if (valid.valid) {
    return dayjs(
      `${valid.date.year}-${valid.date.month}-${valid.date.day}`,
      'YYYY-M-D'
    ).format('YYYY-MM-DD')
  }

  result = extractMonthNameDayYearFromText(text)
  valid = isValidDate(result.at(0))
  if (valid.valid) {
    return dayjs(
      `${valid.date.year}-${valid.date.month}-${valid.date.day}`,
      'YYYY-M-D'
    ).format('YYYY-MM-DD')
  }

  result = extractMonthDayYear(text)
  valid = isValidDate(result.at(0))
  if (valid.valid) {
    return dayjs(
      `${valid.date.year}-${valid.date.month}-${valid.date.day}`,
      'YYYY-M-D'
    ).format('YYYY-MM-DD')
  }

  result = extractDayMonthYear(text)
  valid = isValidDate(result.at(0))
  if (valid.valid) {
    return dayjs(
      `${valid.date.year}-${valid.date.month}-${valid.date.day}`,
      'YYYY-M-D'
    ).format('YYYY-MM-DD')
  }

  result = extractMonthNameDay(text)
  valid = isValidDate(result.at(0))
  if (valid.valid) {
    return dayjs(`${valid.date.month}-${valid.date.day}`, 'M-D').format('MM-DD')
  }

  result = extractDayMonthName(text)
  valid = isValidDate(result.at(0))
  if (valid.valid) {
    return dayjs(`${valid.date.month}-${valid.date.day}`, 'M-D').format('MM-DD')
  }

  result = extractMonthDay(text)
  valid = isValidDate(result.at(0))
  if (valid.valid) {
    return dayjs(`${valid.date.month}-${valid.date.day}`, 'M-D').format('MM-DD')
  }

  result = extractDayMonth(text)
  valid = isValidDate(result.at(0))
  if (valid.valid) {
    return dayjs(`${valid.date.month}-${valid.date.day}`, 'M-D').format('MM-DD')
  }

  return null
}

export const convertNumberToExcelDate = (
  num: number,
  format: string = 'YYYY-MM-DD'
) => {
  if (num <= 0) throw new Error('Invalid Excel Serial Date Number')

  const excelBaseDate = dayjs('1899-12-30')
  const date = excelBaseDate.add(num, 'day')
  return date.format(format)
}
