import { MdDelete } from 'react-icons/md'
import {
  ChangeEvent,
  Dispatch,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Cut, FileStatus, RotationType, TextcutRectangle } from '../../types'
import { useAppDispatch, useAppSelector } from '../../dispatch'
import { setShowSelectionOnly } from '../../slice/showSelectionOnlySlice'
import DropdownButton from '../DropdownButton'
import { useNavigate } from 'react-router-dom'
import { deleteReferencesWithBindingId } from '../../slice/referenceSlice'
import useFiles from '../../hooks/useFiles'
import CustomComboBox from '../SearchAllComboBox'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import KeyboardArrowLeftOutlined from '@mui/icons-material/KeyboardArrowLeftOutlined'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import Tooltip from '../ui/Tooltip'
import ToolbarButton from '../ui/ToolbarButton'
import { ReaderAPI } from '../PdfViewer/types'

type Props = {
  totalPages: number
  currentPage: number
  setCurrentPage: Dispatch<React.SetStateAction<number>>
  setDisplayKonva: Dispatch<React.SetStateAction<boolean>>
  dispatchRotation: Dispatch<{
    type: string
    payload: undefined | number
  }>
  degree: RotationType
  fileId: string
  setSearchAllItem: Dispatch<React.SetStateAction<string[]>>
  setFileId: Dispatch<React.SetStateAction<string>>
  readerAPI: ReaderAPI | null
}

enum ChangePage {
  PREV = 0,
  NEXT = 1,
}

const FileController = ({
  totalPages,
  currentPage,
  setCurrentPage,
  degree,
  fileId,
  setSearchAllItem,
  setFileId,
  readerAPI,
}: Props) => {
  const navigate = useNavigate()

  const references = useAppSelector((state) => state.references.references)
  // const comments = useAppSelector((state) => state.comments.comments)
  const [searchAllInput, setSearchAllInput] = useState(false)
  const [filesDropdown, setFilesDropdown] = useState(true)

  // const renderCommentIcon =
  //   (comments: FileCommentTopic[]) => (elem: TextcutRectangle) => {
  //     if (comments.some((comment) => comment.bindingId === elem.bindingId))
  //       return <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 15 }} />
  //     return <></>
  //   }

  const selectRange = (rect: TextcutRectangle) =>
    Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getItem(rect.sheetId)
      sheet.load('isNullObject')
      await ctx.sync()
      if (sheet.isNullObject) return
      const range = sheet.getRange(rect.rangeAddr)
      range.select()
      sheet.activate()
    })

  const referecnesInput = references.map((ref) => ({
    ...ref,
    id: ref.bindingId,
    label: `${ref.sheetName}-${ref.rangeAddr}`,
    onClick: () => selectRange(ref).catch((err) => console.log(err)),
    itemDeletion: [
      <MdDelete />,
      () =>
        dispatch(deleteReferencesWithBindingId(ref.bindingId)).catch((err) =>
          console.log(err)
        ),
    ] as [JSX.Element, MouseEventHandler],
  }))
  const { files, isSuccess } = useFiles()

  const input =
    files
      ?.filter(
        (file) => file.status === FileStatus.SUCCEEDED && file.type !== 'FOLDER'
      )
      .map((file) => ({
        id: file.fileId,
        label: file.fileName ?? '',
        onClick: () => navigate(`/file/${file.fileId}`, { state: { page: 1 } }),
      })) ?? []

  const pageInputRef = useRef<HTMLInputElement>(null)
  const showSelectionOnly = useAppSelector(
    (state) => state.showSelectionOnly.showSelectionOnly
  )
  const cutMode = useAppSelector((state) => state.cutMode)
  const dispatch = useAppDispatch()
  const onChangePageBtnClick = (changePage: ChangePage) => () => {
    if (changePage === ChangePage.NEXT && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      readerAPI?.jumpToPage(currentPage - 1 + 1)
      // setDisplayKonva(false)
      if (pageInputRef.current)
        pageInputRef.current.value = currentPage + 1 + ''
    } else if (changePage === ChangePage.PREV && currentPage > 1) {
      setCurrentPage(currentPage - 1)
      readerAPI?.jumpToPage(currentPage - 1 - 1)
      // setDisplayKonva(false)
      if (pageInputRef.current)
        pageInputRef.current.value = currentPage - 1 + ''
    }
  }

  const onPageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    if (value > 0 && value <= totalPages) {
      setCurrentPage(value)
      readerAPI?.jumpToPage(value - 1)
    }
  }

  const expandSearchAllInput = () => {
    const { innerWidth } = window
    if (innerWidth < 640) setFilesDropdown(false)
    setSearchAllInput(true)
  }

  const collapesSearchAllInput = () => {
    if (!filesDropdown) setFilesDropdown(true)
    setSearchAllInput(false)
  }

  useEffect(() => {
    if (pageInputRef.current) pageInputRef.current.value = currentPage + ''
  }, [currentPage])

  useEffect(() => {
    const handler = () => {
      const { innerWidth } = window
      if (innerWidth >= 640 && !filesDropdown) setFilesDropdown(true)
      else if (innerWidth < 640 && searchAllInput) setFilesDropdown(false)
    }

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  })

  return (
    <div className="flex w-full bg-[#F5F5F5] min-w-[300px] h-fit justify-between p-1">
      <div className="flex justify-start items-center">
        {/* <Link to={'/files'}>
          <button className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
            <RiArrowGoBackFill />
          </button>
        </Link> */}
        {isSuccess && filesDropdown && (
          <div className="max-h-10 flex transition ease-in-out delay-100 hover:scale-110 p-1">
            <DropdownButton
              input={input}
              icon={<></>}
              tooltip="View another file"
              rearIcon={
                <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '16px' }} />
              }
            />
          </div>
        )}
        {referecnesInput.length > 0 && filesDropdown && (
          <div className="flex justify-center transition ease-in-out delay-100 hover:scale-110 p-1">
            <DropdownButton
              input={referecnesInput}
              icon={<InsertLinkOutlinedIcon sx={{ fontSize: '16px' }} />}
              tooltip="Link "
            />
          </div>
        )}
        <div className="flex justify-center items-center p-1">
          <span
            className={`flex w-fit items-center h-fit ${
              cutMode.type === Cut.VIEW ? 'bg-gray-700' : 'bg-green-700'
            } text-white text-xs/[10px] font-medium  px-2 py-1 rounded-full`}
          >
            {cutMode.type === Cut.TEXTCUT
              ? 'TEXT'
              : cutMode.type === Cut.TABLECUT
              ? 'TABLE'
              : cutMode.type}
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-evenly items-center gap-2 pr-2">
        {/* <DropdownCheckbox /> */}

        {/* {cutMode.transposed && (
          <button className="text-sm text-gray-800 font-bold py-2 px-4 mr-1 rounded sm:inline-flex items-center">
            {<MdPivotTableChart />}
          </button>
        )} */}

        {/* <div className="hidden group relative sm:flex justify-center">
          <button
            className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-2 px-4 mr-1 rounded inline-flex items-center"
            onClick={() =>
              Office.context.ui.displayDialogAsync(
                `https://localhost:3000${window.location.pathname}`
              )
            }
          >
            {<TbFloatLeft />}
          </button>
          <span className="absolute top-14 scale-0 rounded bg-gray-100 p-2 text-xs text-black group-hover:scale-100">
            Float
          </span>
        </div> */}

        <div className="flex group relative justify-center transition ease-in-out delay-100 hover:scale-110">
          <button
            className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
            onClick={() => dispatch(setShowSelectionOnly(!showSelectionOnly))}
          >
            {showSelectionOnly ? (
              <VisibilityOffOutlinedIcon sx={{ fontSize: '16px' }} />
            ) : (
              <VisibilityOutlinedIcon sx={{ fontSize: '16px' }} />
            )}
          </button>
          <div className="absolute top-14 w-max scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
            {showSelectionOnly ? 'View selected' : 'View all'}
          </div>
        </div>

        {/* <div className="flex group relative justify-center transition ease-in-out delay-100 hover:scale-110">
          <button
            className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
            onClick={() =>
              dispatchRotation({
                type: FileRotation.Rotation,
                payload: undefined,
              })
            }
          >
            <Rotate90DegreesCwOutlinedIcon sx={{ fontSize: '16px' }} />
          </button>
          <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
            Rotate
          </div>
        </div> */}

        <div className="justify-center items-center space-x-1 hidden toolbar-expanded:flex">
          <div className="flex transition ease-in-out delay-100 hover:scale-110">
            <Tooltip title="Zoom Out">
              <ToolbarButton onClick={readerAPI?.decreaseZoom}>
                <ZoomOutIcon sx={{ fontSize: '16px' }} />
              </ToolbarButton>
            </Tooltip>
          </div>
          <div className="flex transition ease-in-out delay-100 hover:scale-110">
            <Tooltip title="Zoom In">
              <ToolbarButton onClick={readerAPI?.increaseZoom}>
                <ZoomInIcon sx={{ fontSize: '16px' }} />
              </ToolbarButton>
            </Tooltip>
          </div>
        </div>

        {totalPages > 0 && (
          <div className="relative flex items-center group">
            <div className="flex">
              <input
                ref={pageInputRef}
                type="text"
                inputMode="numeric"
                className="w-5 h-5 text-center text-sm/[10px]"
                onChange={onPageInputChange}
                defaultValue={currentPage}
              />{' '}
            </div>
            <div className="flex text-sm/[10px] whitespace-nowrap">{`/ ${totalPages}`}</div>
            <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
              Pages
            </div>
          </div>
        )}

        {totalPages > 1 && (
          <div className="hidden toolbar-expanded:flex">
            <div className="group relative flex justify-center hover:scale-110 transition ease-in-out delay-100">
              <button
                onClick={onChangePageBtnClick(ChangePage.PREV)}
                className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
              >
                <KeyboardArrowLeftOutlined sx={{ fontSize: '16px' }} />
              </button>
              <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
                Prev
              </div>
            </div>
            <div className="group relative flex justify-center hover:scale-110 transition ease-in-out delay-100">
              <button
                onClick={onChangePageBtnClick(ChangePage.NEXT)}
                className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
              >
                <KeyboardArrowRightOutlinedIcon sx={{ fontSize: '16px' }} />
              </button>
              <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
                Next
              </div>
            </div>
          </div>
        )}

        {!searchAllInput && (
          <div className="flex w-max group relative justify-center transition ease-in-out delay-100 hover:scale-110">
            <button
              className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
              onClick={expandSearchAllInput}
            >
              <SearchOutlinedIcon sx={{ fontSize: '16px' }} />
            </button>
            <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
              Search
            </div>
          </div>
        )}

        {searchAllInput && (
          <div className="flex group relative justify-center ml-1">
            <CustomComboBox
              files={files}
              degree={degree}
              filePage={currentPage}
              fileId={fileId}
              navigate={navigate}
              setCurrentPage={setCurrentPage}
              setSearchAllItem={setSearchAllItem}
              setFileId={setFileId}
              setSearchAllInput={collapesSearchAllInput}
              readerAPI={readerAPI}
            />
          </div>
        )}

        <div className="max-h-10 p-1 flex toolbar-expanded:hidden">
          <DropdownButton
            input={[
              {
                id: 'zoom-in',
                label: 'Zoom In',
                onClick: () => readerAPI?.increaseZoom(),
              },
              {
                id: 'zoom-out',
                label: 'Zoom Out',
                onClick: () => readerAPI?.decreaseZoom(),
              },
              ...(totalPages > 1
                ? [
                    {
                      id: 'next-page',
                      label: 'Next Page',
                      onClick: () => onChangePageBtnClick(ChangePage.NEXT)(),
                    },
                    {
                      id: 'prev-page',
                      label: 'Previous Page',
                      onClick: () => onChangePageBtnClick(ChangePage.PREV)(),
                    },
                  ]
                : []),
            ]}
            icon={<KeyboardDoubleArrowRightIcon sx={{ fontSize: '16px' }} />}
            tooltip="Show more"
            showLabel={false}
            dropdownClassName="right-0 left-auto"
            closeOnClick={true}
          />
        </div>
      </div>
    </div>
  )
}

export default FileController
